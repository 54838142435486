import React from "react";
import "./Promo.scss";

const Promo = () => {
  return (
    <div className="promo-section">
      <div className="video-wrapper">
        <video autoPlay muted loop playsInline>
          <source src={require("../../assets/event-video.mp4")} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay">
          <div className="text-content">
            <h1>Thank You!</h1>
            <p>
              We are deeply grateful to all of you who have supported our project and contributed to its growth. < br/>
              Your enthusiasm fuels our passion and drives us forward. < br/>
              A special thanks to the competitors who participate and to everyone who follows our journey. < br/>
              Your engagement and encouragement mean the world to us. < br/>
              Thank you for being an essential part of our community!
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Promo;
