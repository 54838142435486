import React from "react";
import "./Drivers.scss";
import eventVideo from '../../assets/event-video.mp4';

const driverData = [
  { number: 44, lfsnickname: "N.Petrovic", name: "Nenad", lastname: "Petrovic", age: "25", country: "Serbia", tier: "Professional", lfsteam:"None" ,lfsUsername: "naithidentity" },
  { number: 69, lfsnickname: "M.Kukec",name: "Marko", lastname: "Kukec", age: "25", country: "Croatia", tier: "None", lfsteam:"None" ,lfsUsername: "kukec.m99" },
  { number: 22, lfsnickname: "A.tvalodze",name: "Akaki", lastname: "Tvalodze", age: "22", country: "Georgia", tier: "Professional", lfsteam:"None" ,lfsUsername: "tvalodze" },
  { number: 420, lfsnickname: "C.Renyard",name: "Conor", lastname: "Renyard", age: "20", country: "England", tier: "None", lfsteam:"None" ,lfsUsername: "connorjpeg" },
  { number: 86, lfsnickname: "A.Larsson",name: "Anton", lastname: "Larsson", age: "26", country: "Sweden", tier: "None", lfsteam:"TK☆" ,lfsUsername: "drift_anton" },
  { number: 96, lfsnickname: "P.Fridstrom",name: "Philip", lastname: "Fridstrom", age: "20", country: "Sweden", tier: "None", lfsteam:"TK☆" ,lfsUsername: "kalibern" },
  { number: 73, lfsnickname: "N.Preobrazenskis",name: "Nojus", lastname: "Preobrazenskis", age: "17", country: "Lithuania", tier: "None", lfsteam:"OWT" ,lfsUsername: "AzzyL" },
  { number: 42, lfsnickname: "A.Grubas",name: "Artūras", lastname: "Grubas", age: "21", country: "Lithuania", tier: "None", lfsteam:"None" ,lfsUsername: "lyncenass" },
  { number: 23, lfsnickname: "A.Grant",name: "Anthwaine", lastname: "Grant", age: "19", country: "Jamaica", tier: "None", lfsteam:"None" ,lfsUsername: "NuLrE" },
  { number: 18, lfsnickname: "J.Hardin",name: "Jeremiah", lastname: "Hardin", age: "27", country: "United States", tier: "None", lfsteam:"None" ,lfsUsername: "swerve" },
  { number: 46, lfsnickname: "C.Rodriguez",name: "Camilo", lastname: "Rodriguez", age: "16", country: "United States", tier: "None", lfsteam:"None" ,lfsUsername: "Cyro_W46" },
  // Add more driver data as needed
];

const Drivers = () => {
  return (
    <div id="drivers" className="drivers">
      <div className="contentd">
        <h1><strong>DRIVERS LIST</strong></h1>
        <table>
          <thead>
            <tr>
              <th>Number</th>
              <th>LFS Nickname</th>
              <th>Name</th>
              <th>Lastname</th>
              <th>Age</th>
              <th>Country</th>
              <th>Tier</th>
              <th>LFS Team</th>
              <th>LFS Username</th>
            </tr>
          </thead>
          <tbody>
            {driverData.map((driver, index) => (
              <tr key={index}>
                <td data-label="Number">{driver.number}</td>
                <td data-label="LFS Nickname">{driver.lfsnickname}</td>
                <td data-label="Name">{driver.name}</td>
                <td data-label="Lastname">{driver.lastname}</td>
                <td data-label="Age">{driver.age}</td>
                <td data-label="Country">{driver.country}</td>
                <td data-label="Tier">{driver.tier}</td>
                <td data-label="LFS Team">{driver.lfsteam}</td>
                <td data-label="LFS Username">{driver.lfsUsername}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Drivers;
