import './App.css';

import React from 'react';
import Navbar from './components/navbar/Navbar';
import Navsection from './components/navsec/Navsection';
import Activities from './components/actsec/Activities';
import Events from './components/evesec/Events';
import Drivers from './components/drivsec/Drivers';
import Promo from './components/promsec/Promo';
import Rules from './components/rulsec/Rules';
import Footer from './components/footersec/Footer';

function App() {
  return (
    <div className="App">
      <Navbar />
      <Navsection />
      <Events />
      <Drivers />
      <Promo />
      <Rules />
      <Footer />
    </div>
  );
}

export default App;
