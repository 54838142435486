import React from "react";
import "./Footer.scss";
import logodz from "../../assets/logo.png"; // Your logo
import logolfs from "../../assets/lfs-logo.png"; // LFS logo
import { FaDiscord } from 'react-icons/fa'; // Import Discord icon from react-icons

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className="footer-logos">
          <a href="https://drifterszone.com" target="_blank" rel="noopener noreferrer">
            <img src={logodz} alt="DZ Logo" className="footer-logo-img" />
          </a>
          <a href="https://www.lfs.net" target="_blank" rel="noopener noreferrer">
            <img src={logolfs} alt="LFS Logo" className="footer-lfs-logo" />
          </a>
        </div>
        <div className="footer-links">
          <a href="https://discord.gg/2KJnDWaMwh" target="_blank" rel="noopener noreferrer" className="discord-link">
            <FaDiscord className="discord-icon" /> Join us on Discord
          </a>
        </div>
        <div className="footer-rights">
          <p>&copy; {new Date().getFullYear()} DriftersZone. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
