import React, { useState } from "react";
import "./Navbar.scss";

const Navbar = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <nav className={`navbar ${isOpen ? "show-mobile-menu" : ""}`}>
      <div className="container">
        {/* Logo */}
        <div className="logo">
          <img src="./logo512.png" alt="Logo" />
          <span>Drifters Zone</span>
        </div>

        {/* Navigation Links */}
        <div className={`nav-links ${!isOpen ? "show-nav-links" : ""}`}>

          <a href="#events">Events</a>
          <a href="#drivers">Drivers</a>
          <a href="#rules">Rules</a>
        </div>

        <a href="https://discord.gg/2KJnDWaMwh" className="join-discord-button">Join Discord</a> {/* Add this line */}


        {/* Mobile Menu Icon */}
        <div className="mobile-menu-icon">
          <button onClick={toggleMenu}>
            <svg fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16m-7 6h7"></path>
            </svg>
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="mobile-menu">

          <a href="#events">Events</a>
          <a href="#drivers">Drivers</a>
          <a href="#rules">Rules</a>
          <a href="https://discord.gg/2KJnDWaMwh" className="join-discord-button">Join Discord</a> {/* Add this line */}
        </div>
      )}
    </nav>
  );
};

export default Navbar;
