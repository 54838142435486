import React, { useState } from "react";
import "./Rules.scss";
import { FaBook, FaCar, FaRoad, FaListAlt } from 'react-icons/fa';

const Rules = () => {
  // Postavite "introduction" kao podrazumevanu aktivnu sekciju
  const [activeSection, setActiveSection] = useState("introduction");

  // Izmenite funkciju tako da ne dozvoljava zatvaranje trenutno aktivne sekcije
  const toggleSection = (section) => {
    if (section !== activeSection) {
      setActiveSection(section);
    }
  };

  return (
    <div id="rules" className="rules-container">
      <h1 className="title">EVENT RULES</h1>
      <div className="rules-content">
        <div className="sections">
          <div
            className={`section ${activeSection === "introduction" ? "active" : ""}`}
            onClick={() => toggleSection("introduction")}
          >
            <FaBook className="icon" />
            <h2 className="section-title">Introduction</h2>
          </div>
          <div
            className={`section ${activeSection === "tiers" ? "active" : ""}`}
            onClick={() => toggleSection("tiers")}
          >
            <FaCar className="icon" />
            <h2 className="section-title">Competition Tiers</h2>
          </div>
          <div
            className={`section ${activeSection === "scoring" ? "active" : ""}`}
            onClick={() => toggleSection("scoring")}
          >
            <FaListAlt className="icon" />
            <h2 className="section-title">Scoring System</h2>
          </div>
          <div
            className={`section ${activeSection === "structure" ? "active" : ""}`}
            onClick={() => toggleSection("structure")}
          >
            <FaRoad className="icon" />
            <h2 className="section-title">Event Structure</h2>
          </div>
          <div
            className={`section ${activeSection === "leaderboard" ? "active" : ""}`}
            onClick={() => toggleSection("leaderboard")}
          >
            <FaListAlt className="icon" />
            <h2 className="section-title">Leaderboard System</h2>
          </div>
          <div
            className={`section ${activeSection === "ruling" ? "active" : ""}`}
            onClick={() => toggleSection("ruling")}
          >
            <FaBook className="icon" />
            <h2 className="section-title">Ruling Directive</h2>
          </div>
          <div
            className={`section ${activeSection === "regulations" ? "active" : ""}`}
            onClick={() => toggleSection("regulations")}
          >
            <FaCar className="icon" />
            <h2 className="section-title">Vehicle Regulations</h2>
          </div>
          <div
            className={`section ${activeSection === "tracks" ? "active" : ""}`}
            onClick={() => toggleSection("tracks")}
          >
            <FaRoad className="icon" />
            <h2 className="section-title">Tracks Overview</h2>
          </div>
          <div
            className={`section ${activeSection === "classification" ? "active" : ""}`}
            onClick={() => toggleSection("classification")}
          >
            <FaListAlt className="icon" />
            <h2 className="section-title">Driver Classification</h2>
          </div>
          <div
            className={`section ${activeSection === "tierstructure" ? "active" : ""}`}
            onClick={() => toggleSection("tierstructure")}
          >
            <FaListAlt className="icon" />
            <h2 className="section-title">Tier Structure</h2>
          </div>
        </div>
        <div className="content">
          {activeSection === "introduction" && (
            <div>
              <h3>Competition Introduction</h3>
              <p><strong>Welcome</strong> to the ultimate drifting competition, where skill, precision, and creativity are put to the test. <br/>
              This rulebook outlines the tiers, scoring system, event structure, <br/>
              and key regulations that ensure a fair and thrilling experience for all participants. <br/>
              Whether you're a newcomer in the Street Class or an experienced competitor in the Amateur or Professional Tier, <br/>
              this guide will help you navigate the competition and prepare for success. <br/>
              <strong>Study the rules, gear up, and get ready to showcase your drifting prowess!</strong></p>
            </div>
          )}
          {activeSection === "tiers" && (
            <div>
              <h3>Competition Tiers</h3>
              <h4><strong>Tier 1: Street Class</strong></h4>
              <p><strong>Cars:</strong> Limited to Tier 1 car list.</p>
              <p><strong>Engine Power:</strong> Maximum 300 HP.</p>
              <p><strong>Tires:</strong> Road Normal tires, with a minimum pressure of 2 bar on rear tires.</p>
              <p><strong>Rules:</strong> Focus on the basics of drifting, such as controlled skidding at lower speeds.</p>

              <h4><strong>Tier 2: Amateur Class</strong></h4>
              <p><strong>Cars:</strong> Limited to Tier 2 car list.</p>
              <p><strong>Engine Power:</strong> 300 HP to a maximum of 400 HP.</p>
              <p><strong>Tires:</strong> Road Normal or Road Super tires, with a minimum pressure of 1.3 bar on rear tires.</p>
              <p><strong>Rules:</strong> Focus on drift continuity and technical precision.</p>

              <h4><strong>Tier 3: Professional Class</strong></h4>
              <p><strong>Cars:</strong> Limited to Tier 3 car list.</p>
              <p><strong>Engine Power:</strong> 400 HP to a maximum of 800 HP.</p>
              <p><strong>Tires:</strong> Road Normal or Road Super tires.</p>
              <p><strong>Rules:</strong> Competitors should demonstrate a more aggressive drifting style with higher angles and speed.</p>
              <br/><br/>
              <h4><strong>Unlimited Tier Class</strong></h4>
              <p><strong>Cars:</strong> No limitations.</p>
              <p><strong>Engine Power:</strong> No limitations.</p>
              <p><strong>Tires:</strong> Road Normal or Road Super tires.</p>
              <p><strong>Rules:</strong> Maximum focus on creativity, fluidity, and spectacular moves. <br/>
              This tier is a <strong>show competition</strong> outside of the main event, allowing contestants to showcase unique styles and innovation.</p>
              <br/><br/>
              <p><strong>Note:</strong> Drivers ranked in a specific Tier can only compete in that Tier and the Unlimited Tier Class. <br/>
              <strong>Qualifying:</strong> Drivers must complete three qualifying solo runs for the Tier Class they are competing in.</p>
            </div>
          )}
          {activeSection === "scoring" && (
            <div>
              <h3>Scoring System</h3>
              <p><strong>Tiers 1, 2, 3:</strong></p>
              <ul>
                <li><strong>Line:</strong> Maximum 40 points</li>
                <li><strong>Angle:</strong> Maximum 30 points</li>
                <li><strong>Style:</strong> Maximum 30 points</li>
              </ul>
              <br/><br/>
              <p><strong>Unlimited Tier Class:</strong></p>
              <ul>
                <li><strong>Line:</strong> Maximum 20 points</li>
                <li><strong>Angle:</strong> Maximum 20 points</li>
                <li><strong>Style:</strong> Maximum 20 points</li>
                <li><strong>Special Move:</strong> Maximum 40 points</li>
              </ul>
              <br/><br/>
              <h4><strong>Qualification Points for Tiers</strong></h4>
              <p><strong>To enter the competition, drivers must qualify <br/>
              by achieving a certain number of points during their solo runs. <br/>
              The point thresholds determine the tier a driver can compete in:</strong></p>
              <ul>
                <li><strong>Amateur Class:</strong> 30 - 50 points</li>
                <li><strong>Street Class:</strong> 50 - 70 points</li>
                <li><strong>Professional Class:</strong> 70+ points</li>
              </ul>
              <p><strong>Note:</strong> Drivers should strive for consistency and precision to earn high qualification scores.</p>
            </div>
          )}
          {activeSection === "structure" && (
            <div>
              <h3>Event Structure</h3>
              <h4><strong>1. Pre-Event Setup</strong></h4>
              <ul>
                <li><strong>Setup Time:</strong> 1 hour prior to the event start.</li>
                <li><strong>Registration:</strong> Confirmations and final checks.</li>
              </ul>

              <h4><strong>2. Event Schedule</strong></h4>
              <ul>
                <li><strong>Introduction:</strong> 10 minutes</li>
                <li><strong>Qualifying Runs:</strong> 2 runs per driver</li>
                <li><strong>Break:</strong> 15 minutes</li>
                <li><strong>Top 16 Elimination Rounds:</strong> 5 rounds</li>
                <li><strong>Finals:</strong> Top 3 drivers</li>
                <li><strong>Awards Ceremony:</strong> 15 minutes</li>
              </ul>

              <h4><strong>3. Post-Event Procedures</strong></h4>
              <ul>
                <li><strong>Cleanup:</strong> 30 minutes after the event ends.</li>
                <li><strong>Feedback Collection:</strong> Post-event survey.</li>
              </ul>
            </div>
          )}
          {activeSection === "leaderboard" && (
            <div>
              <h3>Leaderboard System</h3>
              <p><strong>Scoring:</strong> Based on overall points from the rounds.</p>
              <p><strong>Top Positions:</strong> Updated in real-time during the event.</p>
              <p><strong>Final Standings:</strong> Determined by the total points accumulated throughout the competition.</p>
            </div>
          )}
          {activeSection === "ruling" && (
            <div>
              <h3>Ruling Directive</h3>
              <p><strong>Event Management:</strong> Event organizers have final authority on all decisions.</p>
              <p><strong>Protests:</strong> Must be submitted within 30 minutes of an incident. Protests are reviewed by the event committee.</p>
              <p><strong>Penalties:</strong> Will be imposed for rule violations as deemed appropriate by the judges.</p>
            </div>
          )}
          {activeSection === "regulations" && (
            <div>
              <h3>Vehicle Regulations</h3>
              <h4><strong>1. Street Class</strong></h4>
              <ul>
                <li><strong>Engine:</strong> Limited to 300 HP.</li>
                <li><strong>Suspension:</strong> Standard factory setup.</li>
                <li><strong>Brakes:</strong> Standard factory brakes.</li>
              </ul>
              
              <h4><strong>2. Amateur Class</strong></h4>
              <ul>
                <li><strong>Engine:</strong> Up to 400 HP.</li>
                <li><strong>Suspension:</strong> Upgraded suspension allowed.</li>
                <li><strong>Brakes:</strong> Upgraded brakes allowed.</li>
              </ul>

              <h4><strong>3. Professional Class</strong></h4>
              <ul>
                <li><strong>Engine:</strong> Up to 800 HP.</li>
                <li><strong>Suspension:</strong> Custom suspension permitted.</li>
                <li><strong>Brakes:</strong> Custom brakes permitted.</li>
              </ul>
            </div>
          )}
          {activeSection === "tracks" && (
            <div>
              <h3>Tracks Overview</h3>
              <p><strong>Track 1:</strong> Description and layout details.</p>
              <p><strong>Track 2:</strong> Description and layout details.</p>
              <p><strong>Track 3:</strong> Description and layout details.</p>
            </div>
          )}
          {activeSection === "classification" && (
            <div>
              <h3>Driver Classification</h3>
              <p><strong>Drivers:</strong> Classified based on performance in previous events.</p>
              <p><strong>Categories:</strong> Street, Amateur, and Professional.</p>
              <p><strong>Criteria:</strong> Based on points accumulated, consistency, and driving skills.</p>
            </div>
          )}
          {activeSection === "tierstructure" && (
            <div>
              <h3>Tier Structure</h3>
              <p><strong>Street Class:</strong> For beginners with limited vehicle modifications.</p>
              <p><strong>Amateur Class:</strong> For intermediate drivers with some modifications.</p>
              <p><strong>Professional Class:</strong> For advanced drivers with extensive vehicle modifications.</p>
              <p><strong>Unlimited Tier Class:</strong> For showcasing creativity and unique drifting styles.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Rules;
