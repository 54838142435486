import React from "react";
import "./Navsection.scss";
import logodz from "../../assets/logo.png"; // Make sure to import your logo
import logolfs from "../../assets/lfs-logo.png"; // Make sure to import your logo


const Navsection = () => {
  return (
    <section className="nav-section">
      <div className="video-wrapper">
        <video autoPlay muted loop playsInline>
          <source src={require("../../assets/nav-video.mp4")} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
        <div className="overlay"></div> {/* Add the overlay here */}
      </div>
      <div className="content">
        <div className="logo">
          <a href="https://drifterszone.com" target="_blank" rel="noopener noreferrer">
              <img src={logodz} alt="DZ Logo" />
          </a>
          <a href="https://www.lfs.net" target="_blank" rel="noopener noreferrer">
              <img src={logolfs} alt="LFS Logo" />
          </a>
        </div>
        <div className="text">
          <h1>Rev up your engines and drift your way to glory!</h1>
          <p>
            Join our competitive drifting event with a cash prize on the line. < br/>
            Whether you are here to win, watch, or connect with others, < br/>
            we invite you to be part of our vibrant community. < br/>
            Push your limits and leave your mark on the track!</p>
        </div>
      </div>
    </section>
  );
};

export default Navsection;
