import React, { useState } from "react";
import "./Events.scss";
import { FaCalendarDay, FaCalendarCheck, FaCalendarAlt } from 'react-icons/fa';
import septemberImage from '../../assets/ferropolis-track.png'; // Import your image

const Events = () => {
  const [activeSection, setActiveSection] = useState('active'); // Default view
  const [selectedMonth, setSelectedMonth] = useState(null); // Selected month details

  const handleSectionChange = (section) => {
    setActiveSection(section);
    if (section === 'incoming') {
      setSelectedMonth(null); // Reset month details when switching to 'incoming'
    }
  };

  const handleMonthClick = (month) => {
    setSelectedMonth(month);
  };

  const renderEventDetails = (month) => {
    switch (month) {
      case 'September':
        return (
          <div className="event-details">
            <div className="details-content">
              <div className="text-content">
                <h1>FERROPOLIS EVENT</h1>
                <h2>September 28-29, 2024</h2>
                <p><strong>Map:</strong> Ferropolis Track</p>
                <p><strong>Starting Time:</strong> Soon</p>
              </div>
              <div className="image-content">
                <img 
                  src={septemberImage} 
                  alt="September events" 
                />
              </div>
            </div>
          </div>
        );
      case 'October':
      case 'November':
      case 'December':
        return (
          <div className="event-details">
            <div className="details-content">
              <div className="text-content">
                {/* Add details for other months */}
              </div>
              <div className="image-content">
                {/* Add images for other months */}
              </div>
            </div>
          </div>
        );
      default:
        return <p>Please select a month to view the details.</p>;
    }
  };

  const getLeaderboardData = () => {
    const data = {
      Street: [

      ],
      Amateur: [

      ],
      Professional: [
        { number: 44, nickname: "N.Petrovic", age: 25, country: "Serbia", points: 0, },
        { number: 22, nickname: "A.tvalodze", age: 22, country: "Georgia", points: 0, },
        { number: 73, nickname: "	N.Preobrazenskis", age: 17, country: "Lithuania", points: 0, },
      ],
      Unlimited: [
        { number: 44, nickname: "N.Petrovic", age: 25, country: "Serbia", points: 0, },
        { number: 22, nickname: "A.tvalodze", age: 22, country: "Georgia", points: 0, },
        { number: 73, nickname: "	N.Preobrazenskis", age: 17, country: "Lithuania", points: 0, },
        { number: 69, nickname: "	M.Kukec", age: 25, country: "Croatia", points: 0, },
      ],
    };

    // Sort each category's data by points in descending order
    Object.keys(data).forEach(tier => {
      data[tier].sort((a, b) => b.points - a.points);
    });

    return data;
  };

  const leaderboardData = getLeaderboardData();

  const renderLeaderboard = (data) => {
    return (
      <div className="leaderboard-section">
        <h2>Leaderboard</h2>
        <div className="tier-icons">
          {Object.keys(data).map(tier => (
            <div key={tier} className="tier-icon">
              <h3>{tier}</h3>
              <table className="leaderboard-table">
                <thead>
                  <tr>
                    <th>Number</th>
                    <th>Nickname</th>
                    <th>Age</th>
                    <th>Country</th>
                    <th>Points</th>
                  </tr>
                </thead>
                <tbody>
                  {data[tier].map(player => (
                    <tr key={player.number}>
                      <td>{player.number}</td>
                      <td>{player.nickname}</td>
                      <td>{player.age}</td>
                      <td>{player.country}</td>
                      <td>{player.points}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <section id="events" className="events">
      <header className="events-header">
        <h1>EVENTS OVERVIEW</h1>
        <p>Manage and view details of all events including active, upcoming, seasonal, and past events.</p>
      </header>

      <div className="section-icons">
        <div
          className={`icon ${activeSection === 'active' ? 'active' : ''}`}
          onClick={() => handleSectionChange('active')}
        >
          <FaCalendarDay />
          <span>Leaderboard</span>
        </div>
      </div>

      <div className="section-content">
        {activeSection === 'active' && renderLeaderboard(leaderboardData)}
        {activeSection === 'incoming' && (
          <div className="content incoming">
            <div className="season-icons">
              <div
                className={`season-icon ${selectedMonth === 'September' ? 'selected' : ''}`}
                onClick={() => handleMonthClick('September')}
              >
                <FaCalendarAlt />
                <span>September</span>
              </div>
              <div
                className={`season-icon ${selectedMonth === 'October' ? 'selected' : ''}`}
                onClick={() => handleMonthClick('October')}
              >
                <FaCalendarAlt />
                <span>October</span>
              </div>
              <div
                className={`season-icon ${selectedMonth === 'November' ? 'selected' : ''}`}
                onClick={() => handleMonthClick('November')}
              >
                <FaCalendarAlt />
                <span>November</span>
              </div>
              <div
                className={`season-icon ${selectedMonth === 'December' ? 'selected' : ''}`}
                onClick={() => handleMonthClick('December')}
              >
                <FaCalendarAlt />
                <span>December</span>
              </div>
            </div>
            <div className="events-details">
              {renderEventDetails(selectedMonth)}
            </div>
          </div>
        )}
      </div>
    </section>
  );
};

export default Events;
